<template>
  <div class="login-page">
      <div>
          <h2 style="color: white;
    background: rgba(0,0,0,0.5);
    padding: 0 0.4em;
    border-radius: 0.5rem;">超级管理员登录</h2>
      </div>
    <div class="login-content">

        <!--账户密码登录-->
        <el-form ref="form">
          <el-form-item label="账号">
              <el-input size="medium" v-model="userId" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="密码">
              <el-input size="medium" v-model="password" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
	        <div class="login-button">
		        <el-form-item>
			        <el-button size="medium" type="primary" @click="login">立即登录</el-button>
		        </el-form-item>
	        </div>
        </el-form>
    </div>
  </div>
</template> 
<script>
import {getRsaCode} from "@/utils/utils.js";
import {setCookie, setToken} from '@/utils/auth'

export default {
  data() {
    return { 
      userId: '',
      password: ''
    };
  },
  mounted() {
	  setCookie('userType', 'superAdmin')
	  setCookie('saasId', 1)
  },
  methods: {
    async login() {

        if (!this.userId) {
            this.$message({
                message: "请输入账号",
            });
            return
        }
        if (!this.password) {
            this.$message({
                message: "请输入密码",
            });
            return
        }

        this.$http.post('/saas/saas/login', {
          userId: this.userId,
          password: getRsaCode(this.password)
        }).then(res => {
	        setToken(res.data.token)
	        this.$router.push('/saas/userList').then(res => {
		        this.$forceUpdate()
		        window.location.reload()
	        })
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  width: 100%;
	min-height: 100vh;
  display: flex;
	//background: linear-gradient(90deg, #ffffff 0%, #edfffd 25%, #fff 50%, #f9fffe 75%, #fff 100%);
	background-image: url('../../assets/img/login_bg.png');
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-content {
	padding: 2rem 2rem 0;
	border: solid 1px #9f0000;
    border-radius: 10px;
	background: rgba(255,255,255,0.5);
		width: 30rem;
	.login-button {
		display: flex;
		justify-content: center;
	}
	.el-form-item--mini.el-form-item {
		margin-bottom: 40px;
	}
}
</style>